import React, {lazy, Suspense} from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import {Row, Spin} from 'antd';

import './App.css';
import {DBlockStateProvider} from "./store/DBlockStateProvider";
import {ErrorBoundary} from "react-error-boundary";
import ErrorHandler from "./store/ErrorHandler";
import {MessageProvider} from "./store/MessageProvider";

const PublicRoutes = lazy(() => import('./router/PublicRoutes'));
const PrivateRoutes = lazy(() => import('./router/PrivateRoutes'));
const AppLayout = lazy(() => import('./components/Layout/AppLayout'));

const ProjectSettingPage = lazy(() => import('./pages/Admin/ProjectSettingPage'));
const UserSettingPage = lazy(() => import('./pages/Admin/UserSettingPage'));

const DMPage = lazy(() => import('./pages/DMPage'));

const QueryPage = lazy(() => import('./pages/QueryPage'));

const LoginPage = lazy(() => import('./pages/LoginPage'));
const ProjectPage = lazy(() => import('./pages/ProjectPage'));
const OverviewPage = lazy(() => import('./pages/Main/OverviewPage'));
const SubjectPage = lazy(() => import('./pages/Main/SubjectPage'));
const VisitPage = lazy(() => import('./pages/Main/VisitPage'));
const ReliabilityOverviewPage = lazy(() => import('./pages/Reliability/OverviewPage'));

const LogPage = lazy(()=> import('./pages/Admin/LogPage'));


const ExpiredPage = lazy(() => import('./pages/ExpiredPage'));
const ErrorPage = lazy(() => import('./pages/ErrorPage'));

const App = () => {
    return (
        <Router>
            <ErrorBoundary FallbackComponent={ErrorHandler}>
                <MessageProvider>
                    <DBlockStateProvider>
                        <Suspense fallback={<Row justify='center' align='middle' style={{minHeight: '100vh'}}><Spin
                            size='large'/></Row>}>
                            <Routes>
                                <Route element={<AppLayout/>}>
                                    <Route path='/' element={<LoginPage/>}/>
                                    <Route element={<PublicRoutes/>}>
                                        <Route path='/project' element={<ProjectPage/>}/>
                                        <Route path='/query/:userPK' element={<QueryPage/>}/>
                                        <Route path='/overview/:projectPK' element={<OverviewPage/>}/>
                                        <Route path='/subject/:projectPK' element={<SubjectPage/>}/>
                                        <Route path='/visit/:projectPK' element={<VisitPage/>}/>
                                        <Route path='/reliability/overview/:projectPK'
                                               element={<ReliabilityOverviewPage/>}/>
                                    </Route>
                                    <Route element={<PrivateRoutes/>}>
                                        <Route path='/admin/project' element={<ProjectSettingPage/>}/>
                                        <Route path='/admin/user' element={<UserSettingPage/>}/>
                                        <Route path='/admin/log' element={<LogPage/>}/>
                                        <Route path='/dm/project' element={<DMPage/>}/>
                                    </Route>
                                </Route>
                                <Route exact path='/expired' element={<ExpiredPage/>}/>
                                <Route path='*' element={<ErrorPage/>}/>
                            </Routes>
                        </Suspense>
                    </DBlockStateProvider>
                </MessageProvider>
            </ErrorBoundary>
        </Router>
    )
}

export default App;