import { createContext, useState } from 'react';

export const DBlockStateContext = createContext();

export const DBlockStateProvider = ({ children }) => {
    const [currentProjectId, setCurrentProjectId] = useState(null);

    return (
        <DBlockStateContext.Provider value={{ currentProjectId, setCurrentProjectId }}>
            {children}
        </DBlockStateContext.Provider>
    );
}