import React from 'react';
import ReactDOM from 'react-dom/client';
import 'antd/dist/reset.css';
import App from './App';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import AxiosConfig from "./AxiosConfig";
import { ConfigProvider } from "antd";

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient({defaultOptions: {queries: {retry: 0, suspense: true, cacheTime: 3600000, refetchOnWindowFocus: false}}});
const basicToken = {token: {fontSize: 12}};

console.log(process.env.REACT_APP_API_URL);
root.render(
    <ConfigProvider theme={basicToken}>
        <QueryClientProvider client={queryClient}>
            <AxiosConfig>
                {!process.env.REACT_APP_API_URL ? <ReactQueryDevtools /> : null}
                <App />
            </AxiosConfig>
        </QueryClientProvider>
    </ConfigProvider>
);