import ErrorPage from "../pages/ErrorPage";
import axios from "axios";

const ErrorHandler = ({error}) => {
    const ErrorInfo = [
        {id: 403, title: 'Access Denied', subtext: 'You dont\'t have permission to access this page.'},
        {id: 404, title: 'Page Not Found', subtext: 'Please make sure you\'ve entered the correct URL.'},
        {id: 500, title: 'Unexpected Error', subtext: 'The website can\'t display ths page. Please try again or Contact us.'},
    ]

    if(axios.isAxiosError(error)) {
        const selectedError = ErrorInfo.find(e => e.id === error.response.status) ?? ErrorInfo[ErrorInfo.length - 1];
        return <ErrorPage title={selectedError.title} subText={selectedError.subtext}/>
    }

    return <ErrorPage title={ErrorInfo[ErrorInfo.length - 1].title} subtext={ErrorInfo[ErrorInfo.length - 1].subtext}/>
};

export default ErrorHandler;