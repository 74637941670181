import React, { createContext } from "react";
import { message } from "antd";

export const MessageContext = createContext(null);

export const MessageProvider = ({ children }) => {
    const [messageApi, contextHolder] = message.useMessage();

    return (
        <MessageContext.Provider value={messageApi}>
            {children}
            {contextHolder}
        </MessageContext.Provider>
    );
};