import axios from 'axios';

const AxiosConfig = ({ children }) => {
    axios.defaults.withCredentials = true;
    axios.defaults.baseURL = process.env.REACT_APP_API_URL === undefined ?
        'https://dev-api.dermatrial.com'
        // 'http://192.168.0.125:8080'
        :
        'https://' + process.env.REACT_APP_API_URL + '.dermatrial.com';
    axios.defaults.headers['X-AUTH-TOKEN'] = localStorage.getItem('token');

    return children;
};

export default AxiosConfig;