import { useNavigate } from "react-router-dom";
import { Button, Result, Space } from "antd";
import { Typography } from "antd";

const ErrorPage = ({title, subtext}) => {
    const { Text} = Typography;
    const navigate = useNavigate();
    const defaultMessage = {
        title: 'Page Not Found', //404
        subtext: 'Please make sure you\'ve entered the correct URL.'
    }

    return <Result status='error'
                   title={
                       <Space direction='vertical'>
                           <Text style={{fontSize: '24px'}}>{title ?? defaultMessage.title}</Text>
                           <Text style={{fontSize: '24px'}}>{subtext ?? defaultMessage.subtext}</Text>
                       </Space>}
                   extra={[<Button type="primary" size="large" onClick={() => navigate(-1)}>Back</Button>]}/>
}

export default ErrorPage